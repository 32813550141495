import './App.css';
import { Route, Routes } from "react-router";
import Sidebar from './Components/Sidebar';
import Register from './Components/Register';
import AddQuestions from './Components/AddQuestions';
import Header from './Components/Header';
import Auth from './Components/Auth';
import ResultForm from './Components/ResultForm'
import ResultReport from './Components/ResultReport'
import ResultOverview from './Components/ResultOverview';
import Error500 from './Components/Error500';
// import Error404 from './Components/Error404';

function App() {
  return (
    <div className="App">
      <Routes>
      <Route exact path='/' element={<Auth/>}/>
      <Route exact path='/sidebar' element={<Sidebar/>}/>
      <Route exact path='/register' element={<Register/>}/>
      <Route exact path='/result' element={<ResultForm/>}/>
      <Route exact path='/report' element={<ResultReport/>}/>
      <Route exact path='/addquestions' element={<AddQuestions/>}/>
      <Route exact path='/header' element={<Header/>}/>
      <Route exact path='/auth' element={<Auth/>}/>
      <Route exact path='/resultoverview' element={<ResultOverview/>}/>
      <Route exact path='/error500' element={<Error500/>}/>
      {/* <Route element={<Error404/>}/> */}
      </Routes>
    </div>
  );
}

export default App;
