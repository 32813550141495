import React, { useEffect } from 'react'
import './ResultReport.css'
import AkgecLogo from '../Images/AKGEC_LOGO.png'
import {PDFExport} from '@progress/kendo-react-pdf'
import Button from '@mui/material/Button';
import axios from "axios";
import Api from '../Api';
import { useNavigate } from 'react-router-dom';

function ResultReport(props) {
  const pdfExportComponent = React.useRef(null)
  const handleExport = (event) => {
    pdfExportComponent.current.save();
  }

    useEffect(() => {
      console.log(props)
    },)
    

  const value = {
      "email": props.email
  }
  
  const handleUpdate = async () => {
    try {
        await axios
          .post(Api.status, value)
          .then(function (response) {
            console.log(response);
          });
          window.alert("Result Updated")
      } catch (e) {
        console.log(e);
        window.alert("Update Failed");
      }
    }

  const getResponse = (res) => {
      if(res === "one"){
          return "a"
      }
      else if(res === "two"){
          return "b"
      }
      else if(res === "three"){
          return "c"
      }
      else if(res === "four"){
          return "d"
      }
  }

  const navigate = useNavigate()

  return (
    <div className='ResultReport'>
    <PDFExport ref={pdfExportComponent} paperSize="A4">
    <div id="header">
        <img src={AkgecLogo} alt="logo"/>
        <span id="guh">Ajay Kumar Garg Engineering College</span>
    </div>
    <div id="tex">
        <h4 className="t3">RESULT REPORT</h4>
        <h3 className="t2">{props.name}</h3>
        {/* <h4 className="t4">Date of birth : {props.dob}</h4> */}
        <h6 className="t4">Email Id : {props.email}</h6>
        <h6 className="t4">Phone No. : {props.mobile}</h6>
        <h4 className="t2">Marks Obtained :</h4>
    </div>
    <div>
        <table id="tab">
            <tr>
                <td >Physics</td>
                <td>{props.phy}</td>
            </tr>
            <tr>
                <td>Chemistry</td>
                <td>{props.chem}</td>
            </tr>
            <tr>
                <td>Maths</td>
                <td>{props.math}</td>
            </tr>
            <tr>
                <td id="top">TOTAL</td>
                <td id="to">{props.total}</td>
            </tr>
        </table>
       </div>
       <h4 className="t2">Question Responses :</h4>
       <div>
        <table id="tab">
            <tr>
                <td >Question No.</td>
                <td>Response</td>
            </tr>

            {props.response.map((item) => (
                <tr>
                    <td>{item.count}</td>
                    <td>
                        {getResponse(item.response)}
                    </td>
                </tr>
             ))}
        </table>
        <p>Powered by Blockchain Research Lab</p>
       </div>
       </PDFExport>
       <div className='ButtonGroup'>
       <Button variant="contained"  className="rtbtn" onClick={() => navigate("/register")}>Back</Button>
       <div className='space'></div>
       {/* <Button variant="contained" onClick={handleUpdate}>Update</Button> */}
       <div className='space'></div>
       <Button variant="contained"  className="rtbtn" onClick={handleExport}>Print</Button>
       </div>
    </div>
  )
}

export default ResultReport