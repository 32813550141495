import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./ResultOverview.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Paper from "@mui/material/Paper";
import Api from "../Api";
import Error500 from "./Error500";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function ResultOverview() {
  const [userData, setUserdata] = useState();
  const [display, setDisplay] = useState(true);
  const [searchTitle, setSearchTitle] = useState("");
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isoDate, setISODate] = useState("");

  const token = localStorage.getItem("token")
  const username = localStorage.getItem("username")

  useEffect(() => {
    loadusers();
    console.log(token)
  }, []);

  const statusComponents = (value) => {
    if (value) {
      return <h4 className="resultgn">Result Printed</h4>;
    } else {
      return <h4 className="resultbu">Not Printed</h4>;
    }
  };

  const loadusers = async () => {
    try {
      await axios.post(Api.overview, null, {headers: {authtoken: token, username: username}}).then(function (response) {
        console.log(response.data);
        setUserdata(response.data);
        console.log(userData);
        setDisplay(false);
      });
    } catch (e) {
      console.log(e);
      setError(true)
      // window.alert("Failed To Load");
    }
  };

  return (
    <>
      {error ? (
        <Error500 />
      ) : (
        <>
          {" "}
          <div className="Result">
            <Header />
            <div className="parent-container">
              <div className="left-div">
                <Sidebar />
              </div>

              <div className="right-div">
                <div className="top-bar-right">
                  <div className="head-text">RESULT STATUS</div>
                </div>
                <div className="scroll">
                  <form className="searchdiv">
                    <input
                      type="text"
                      placeholder="Enter a Name, Phone Number or Email"
                      className="searchbar"
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date)
                        console.log(date);
                        var str = date.toString();
                        setISODate(str.substr(0, 15));
                        console.log(isoDate)
                      }}
                      dateFormat='yyyy-MM-dd'
                      isClearable
                      showYearDropdown
                      scrollableMonthYearDropdown
                      placeholderText="Select Date"
                    />
                  </form>
                  {display ? (
                    <div className="CentralLoader">
                      <ClipLoader size={50} color={"#007ab6"} loading={true} />
                    </div>
                  ) : (
                    userData
                      .filter((value) => {
                        if (searchTitle === "" && isoDate === "") {
                          return value;
                        } else if (isoDate !== "" && searchTitle === "") {
                          const y = new Date(value.updatedAt);
                          const z = y.toString();
                          const x = z.substr(0, 15);
                          if (x === isoDate) {
                            return value;
                          }
                        } else if (selectedDate === null && searchTitle !== "" &&
                          (value.name
                            .toLowerCase()
                            .includes(searchTitle.toLowerCase()) ||
                            value.phoneNumber
                              .toLowerCase()
                              .includes(searchTitle.toLowerCase()) ||
                            value.email
                              .toLowerCase()
                              .includes(searchTitle.toLowerCase()))
                        ) {
                          return value;
                        }
                      })
                      .reverse()
                      .map((item) => (
                        <>
                          <Paper elevation={3} className="boxg">
                            <div className="leftside">
                              <h4 className="leftside-name">{item.name}</h4>
                              <h4>{item.phoneNumber}</h4>
                              <h4>{item.email}</h4>
                            </div>
                            <div className="rightside">
                              <h4>Status:</h4>
                              {statusComponents(item.pdf)}
                            </div>
                          </Paper>
                        </>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ResultOverview;
