import React, { useState } from "react";
import "./Register.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import axios from "axios";
import { ClipLoader} from "react-spinners";
import { Navigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Api from "../Api";

export default function Register() {
  const [display, setDisplay] = useState(true);
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    name: "",
    phoneNumber: "",
    dob: "",
    email: "",
    course: "b.tech",
  });

  const token = localStorage.getItem("token")
  const username = localStorage.getItem("username")

  const handleForm = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setValues((prevValue) => {
      if (name === "name") {
        return {
          name: value,
          phoneNumber: prevValue.phoneNumber,
          dob: prevValue.dob,
          email: prevValue.email,
          course: prevValue.course,
        };
      } else if (name === "phoneNumber") {
        return {
          name: prevValue.name,
          phoneNumber: value,
          dob: prevValue.dob,
          email: prevValue.email,
          course: prevValue.course,
        };
      } else if (name === "email") {
        return {
          name: prevValue.name,
          phoneNumber: prevValue.phoneNumber,
          dob: prevValue.dob,
          email: value,
          course: prevValue.course,
        };
      }
    });
  };

  const submitForm = async () => {
    // Email validation regex
    const emailRegex = /^\S+@\S+\.\S+$/;
    // Phone number validation regex (exactly 10 digits)
    const phoneRegex = /^\d{10}$/;
  
    if (!values.name.trim()) {
      window.alert("Name is missing");
      return;
    }
    if (!values.phoneNumber.trim() || !phoneRegex.test(values.phoneNumber.trim())) {
      window.alert("Please enter a valid 10-digit Phone Number");
      return;
    }
    if (!values.email.trim() || !emailRegex.test(values.email.trim())) {
      window.alert("Please enter a valid Email");
      return;
    }
    
    setDisplay(false);
    try {
      await axios.post(Api.register, values, {headers: {authtoken: token, username: username}})
        .then(function (response) {
          console.log(response);
          setLoader(true);
          setValues({
            name: "",
            phoneNumber: "",
            dob: "",
            email: "",
            course: "b.tech",
          })
        });
    } catch (e) {
      console.log("exception occured");
      console.log(e);
      window.alert("User already Registered");
      setDisplay(true);
      setLoader(false);
    }
  };
  
  

  if (localStorage.getItem("token") === null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {display ? (
        <div className="Register">
          <Header />

          <div className="parent-container">
            <div className="left-div">
              <Sidebar />
            </div>
            <div className="right-div">
              <div className="top-bar-right">
                <div className="head-text">REGISTRATION FOR TEST</div>
              </div>
              <Paper elevation={3} className="Register__Paper">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  className="filled-basic"
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={values.name}
                  sx={{ m: 1, minWidth: 300, minHeight: 24 }}
                  onChange={handleForm}
                />
                <br></br>
                <TextField
                  className="filled-basic"
                  label="Phone No."
                  name="phoneNumber"
                  variant="outlined"
                  value={values.phoneNumber}
                  sx={{ m: 1, minWidth: 300 }}
                  onChange={handleForm}
                />
                <br />
                <TextField
                  className="filled-basic"
                  label="Email Id"
                  name="email"
                  variant="outlined"
                  value={values.email}
                  sx={{ m: 1, minWidth: 300 }}
                  onChange={handleForm}
                />
                <br />
                <Button variant="contained" className="Submitbutton" onClick={submitForm}>
                  SUBMIT
                </Button>
              </Box>
              </Paper>
            </div>
          </div>
        </div>
      ) : loader ? (
        <>
          <div className="Register">
            <Header />

            <div className="parent-container">
              <div className="left-div">
                <Sidebar />
              </div>

              <div className="right-div">
                <h1 className="Success_Message">
                  User Registered Successfully
                </h1>
                <br/>
                {/* <Link to="/register"> */}
                <Button  className="BackButton" variant="contained" onClick={() => setDisplay(true)}>
                  Back
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="CentralLoader">
          <ClipLoader size={100} color={"#007ab6"} loading={true} />
        </div>
      )}
    </>
    //   </div>
    // </div>
  );
}
