import React from "react";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Api from "../Api";
// import { Navigate } from "react-router-dom";

function Sidebar(props) {
  // const removeToken = () => {
  //   console.log("Token Removed")
  //   localStorage.removeItem("token")
  //   return <Navigate to="/" />;
  // }
  const navigate = useNavigate()
  const restartServer = async () => {
    try{
      await axios.get(Api.restart).then(function(response){
        if(response.data.message === "Handling GET request")
          {
            window.alert("Server Restarted Successfully")
          }
        else{
          window.alert("Unable Restart Server Contact Admin")
        }
      })
    } catch (e){
      console.log(e)
      window.alert("Unable Restart Server Contact Admin")
    }
  }

  const handleLogOut = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("username")
    navigate("/")
  }

  return (
    <div className="Sidebar">
      <div className="top-bar">
        <div className="reg">
          <Link to="/register" className="link">
            REGISTRATION FOR TEST
          </Link>
          <hr className="Sidebar__Divider"/>
        </div>
      </div>

      <div className="sidebar">
         <div className="resultoverview">
          <Link to="/resultoverview" className="link">
            RESULT STATUS
          </Link>
          <hr className="Sidebar__Divider"/>
        </div> 
        <div className="res">
          <Link to="/result" className="link">
            PRINT RESULT
          </Link>
          <hr className="Sidebar__Divider"/>
        </div>
        <div className="server_div">
          <p className="server_button" onClick={handleLogOut} style={{
            background: "#007ab6",
            borderTop:"1px solid white"
          }}>LOG OUT</p>
          <p className="server_button" onClick={restartServer}>Restart Server</p>
        </div>
        {/* <div className="logout_div">
          <p className="logout_button" onClick={removeToken}>Logout</p>
        </div>
         */}
      </div>
    </div>
  );
}

export default Sidebar;
