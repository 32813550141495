import React from 'react'
import './Auth.css'
import axios from "axios";
import Header from "./Header.js"
import { Navigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Error500 from "./Error500"
import Api from '../Api';

function Auth() {
  // const [login, setLogin] = React.useState(false);
  const [error,setError] = React.useState(false)
  const [loader, setLoader] = React.useState(true);

  const [values, setValues] = React.useState({
    username: "",
    password: ""
  });

  const handleForm = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setValues((prevValue) => {
      if (name === "username") {
        return {
          username: value,
          password: prevValue.password,
        };
      } else if (name === "password") {
        return {
          username: prevValue.username,
          password: value,
        };
      }
    });
  };

  const submitForm = async () => {
    setLoader(false);
    try {
      await axios
        .post(Api.auth, values)
        .then(function (response) {
          if(response.data === "Wrong Credential"){
              window.alert("Wrong Credentials")
          }
          else{
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("username", response.data.user.username)
            // setLogin(true);
            console.log(response);  
          }
        });setLoader(true);
    } catch (e) {
        // return <Error500/>
      // console.log("exception occured");
      // console.log(e);
      setLoader(true);
      setError(true)
      // window.alert("Enter Correct Username or Password")
    }
  };

  if (localStorage.getItem("token") != null) {
    return <Navigate to="/register" />;
  }


  return (
    <>
    {loader ? error? <Error500/> :(
      <>
        <Header />
        <div className="maindiv1">
          <div className="maindiv2">
            <h2 className="mainhead1">Admin Login</h2>
            <p className="mainpara">Enter your details to Proceed</p>
            <form>
              <div className="formdiv">
                <b>Enter your User id</b>
                <input
                  type="text"
                  className="input"
                  name="username"
                  value={values.username}
                  onChange={handleForm}
                ></input>
              </div>
              <div className="formdiv">
                <b>Enter your Password</b>
                <input
                  type="password"
                  className="input"
                  name="password"
                  value={values.password}
                  onChange={handleForm}
                ></input>
              </div>
            </form>
            <button className="submitbtn" onClick={submitForm}>
              SUBMIT
            </button>
          </div>
        </div>
      </>
    ) : (
      <div className="CentralLoader">
        <HashLoader size={60} color={"#007ab6"} loading={true} />
      </div>
    )}
  </>
  )
}

export default Auth