const url = "https://api.admission.portal.brlakgec.com";
// const url = "http://ec2-13-233-160-28.ap-south-1.compute.amazonaws.com";


const Api = {
    "auth": url+'/student/login-admin',
    "register": url+'/register',
    "generate": url+'/student/generate',
    "printresult": url+'/student/results',
    "overview": url+'/student/users',
    "status": url+'/student/status',
    "restart": url
}

export default Api;
