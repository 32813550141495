import React from "react";
import "./ResultForm.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import ResultReport from "./ResultReport";
import Api from "../Api";
import Error500 from "./Error500";

function ResultForm() {
  const token = localStorage.getItem("token")
  const username = localStorage.getItem("username")
  const [value, setValue] = React.useState({
    detail: "",
  });
  const [display, setDisplay] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState();
  const [error,setError] = React.useState(false);
  const [emailPayload, setEmailPayload] = React.useState({
    "email": ""
  })
  const handleChange = (event) => {
    setValue(() => {
      return {
        detail: event.target.value,
      };
    });

    setEmailPayload(() => {
      return {
        "email": event.target.value
      }
    })
  };

  const handleError = () => {
    window.alert("Result Not Found!")
    setDisplay(true)
  }

  const handleSubmit = async () => {
    setDisplay(false);
    try {
      await axios
        .post(Api.printresult, value, {headers: {authtoken: token, username: username}})
        .then(function (response) {
          console.log(response);
          response.data.data.length
            ? setData(response.data)
            : handleError();
        });

      await axios
        .post(Api.status, emailPayload, { headers: { authtoken: token, username: username } })

      setLoader(true);
    } catch (e) {
      console.log(e);
      setError(true)
      // window.alert("User not Found");
      // setDisplay(true);
    }
  };

  const handleGenerate = async () => {
    try {
      await axios
        .post(Api.generate, value, {headers: {authtoken: token, username: username}})
        .then(function (response) {
          console.log(response);
        });
      window.alert("Result Generated Successfully");
    } catch (e) {
      console.log(e);
      window.alert("Internal Server Error");
      setDisplay(true);
    }
  };

  if (localStorage.getItem("token") === null) {
    return <Navigate to="/" />;
  }

  return (
    <div className="ResultForm">
      {display ?  (
        <>
          <Header />
          <div className="parent-container">
            <div className="left-div">
              <Sidebar />
            </div>

            <div className="right-div">
              <div className="top-bar-right">
                <div className="head-text">PRINT RESULT</div>
              </div>
              <div className="regdiv1">
                <div className="regdiv2">
                  <h2 className="regheading">RESULT</h2>
                  <hr></hr>
                  <br />
                  <TextField
                    id="outlined-basic"
                    label="Enter email or phoneNo"
                    variant="outlined"
                    name="detail"
                    onChange={handleChange}
                    value={value.detail}
                  />
                  <br />
                  <br />
                  <button className="gen-btn btn" onClick={handleGenerate}>
                    GENERATE RESULT
                  </button>
                  <button className="btn" onClick={handleSubmit}>
                    DOWNLOAD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : error? <Error500/>: loader ? (
        <ResultReport
          name={data.data[0].name}
          // dob={data.dob}
          email={data.data[0].email}
          mobile={data.data[0].phoneNumber}
          phy={data.data[0].phy}
          chem={data.data[0].chemistry}
          math={data.data[0].maths}
          total={data.data[0].score}
          response={data.data[0].responses}
        />
      ) : (
        <div className="CentralLoader">
          <BounceLoader size={100} color={"#007ab6"} loading={true} />
        </div>
      )}
    </div>
  );
}

export default ResultForm;
