import React from 'react'
import './Error500.css'
import Header from './Header'
import  Error500image from '../Images/error500.png'
import Button from '@mui/material/Button';
import axios from "axios";
import Api from "../Api";

function Error500() {

  const restartServer = async () => {
    try{
      await axios.get(Api.restart).then(function(response){
        if(response.data.message === "Handling GET request")
          {
            window.alert("Server Restarted Successfully")
          }
        else{
          window.alert("Unable Restart Server Contact Admin")
        }
      })
    } catch (e){
      console.log(e)
      window.alert("Unable Restart Server Contact Admin")
    }
  }

  return (
    <div className='Error500'>
        <Header/>
        <img src={Error500image} alt='#' className='Error500__image'/>
        <h2 className='Error500__error'>ERROR 500:</h2>
        <h4 className='Error500_message'>Internal Server Error</h4>
        <Button variant="text" onClick={restartServer}>Click here to Restart Server</Button>
        <div className='Error500_detailsdiv'>
            <h5 className='Error500_Details'>Contact Developers: </h5>
            <h5 className='Error500_Details'>Ayush Rawat - +91 90154 75406</h5>
            <h5 className='Error500_Details'>Vaibhav Raj - +91 84779 37784</h5>
        </div>
    </div>
  )
}

export default Error500