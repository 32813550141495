import React from 'react'
import './Header.css'
import  Akgeclogo from '../Images/Untitled-2.png'
import Brllogo from '../Images/Blockchain (1).png'

function Header() {
  return (
    <div className='adheaderpdiv1'>
    <div className='adheaderpdiv2'>
      <div className='adheaderc1div'>
        <img src={Akgeclogo} alt="#" height="100%" width="100%" />
      </div>
      <div className='adheaderc2div'>
        <img src={Brllogo} alt="#" height="100%" width="100%" />
      </div>
    </div>
  </div>
  )
}

export default Header